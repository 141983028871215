import $ from "jquery";
import { IComponent } from "../types/IComponent.type";

interface JQueryValidate<T extends HTMLElement> extends JQuery<T> {
    valid?: () => boolean;
}

class FormLoader {
    static get config(): IComponent {
        return {
            namespace: "al.fl",
            id: "form-loader",
            pluginName: "form-loader",
        };
    }

    $el: JQueryValidate<HTMLFormElement>;
    $submitBtnEl: JQueryValidate<HTMLElement>;
    $statusEl: JQueryValidate<HTMLElement>;
    loadingClass: string;

    constructor(context: HTMLFormElement) {
        this.$el = $<HTMLFormElement>(context);
        this.$submitBtnEl = this.$el.find("[data-form-loader-submit]");
        this.$statusEl = this.$el.find("[data-form-status]");
        this.loadingClass = this.$el.attr("data-form-loader-button-class");

        this.onFormSubmit = $.proxy(this.onFormSubmit, this);

        this.attachEvents();

        this.onInit();
    }

    public attachEvents(): void {
        this.$el.on("submit", this.onFormSubmit);
    }

    public onInit(): void {
        if (this.$statusEl.length !== 0) {
            this.$statusEl[0].scrollIntoView();
        }
    }

    public onFormSubmit(): void {
        if (this.$el.valid()) {
            this.$submitBtnEl.attr("disabled", "true").addClass(this.loadingClass);
        }
    }
}

export default FormLoader;
