import { IComponent } from "../types/IComponent.type";

class PracticeStatementLetter {
    static get config(): IComponent {
        return {
            namespace: "al.psl",
            id: "practice-statement-letter",
            pluginName: "practice-statement-letter",
        };
    }

    el: HTMLElement;
    mobileButton: HTMLElement;
    mobileNavigation: HTMLElement;

    constructor(context: HTMLElement) {
        this.el = context;
        this.attachEvents();
    }

    public attachEvents(): void {
        this.setupScrollSpy();
    }

    private setupScrollSpy() {
        if (Array.prototype.find == null) {
            return;
        }

        const activeClass = "practice-statement-contents-active";
        const watchers = [].slice.apply(this.el.querySelectorAll(`[data-scroll-watcher='true']`)) as Element[];
        const areas = [].slice.apply(this.el.querySelectorAll(`[data-scroll-area='true']`)) as Element[];

        function onScroll() {
            const areaResults = [];

            areas.forEach(area => {
                const boundingRect = area.getBoundingClientRect();
                const areaScrollTop = boundingRect.top + document.documentElement.scrollTop - 2;

                if (window.pageYOffset > areaScrollTop) {
                    const offset = window.pageYOffset - areaScrollTop;
                    areaResults.push([area, offset]);
                }
            });

            // Find closest area to the scroll top.
            const nearestWatcher = areaResults.sort((a, b) => a[1] - b[1])[0];

            watchers.forEach(watcher => {
                if (watcher.className.indexOf(activeClass) != -1) {
                    watcher.classList.remove(activeClass);
                }
            });

            if (nearestWatcher != null) {
                const areaId = nearestWatcher[0].getAttribute("id");
                const watcher = watchers.find(watcher => watcher.getAttribute("href").substr(1) == areaId);

                if (watcher != null && watcher.className.indexOf(activeClass) == -1) {
                    watcher.classList.add(activeClass);
                }
            }
        }

        function preventSetup() {
            return window.matchMedia("(max-width: 992px)").matches;
        }

        if (preventSetup()) {
            return;
        }

        document.addEventListener("scroll", onScroll, { passive: true });
    }
}

export default PracticeStatementLetter;
