import $ from "jquery";

$.validator.addMethod(
    "maxfilesize",
    function(value: string, element: HTMLInputElement, params: string) {
        const maxSize = parseFloat(params);

        if (maxSize == null || isNaN(maxSize)) {
            return true;
        }

        let fileSize = 0;

        for (let i = 0; i < element.files.length; i++) {
            fileSize += element.files[i].size;
        }

        if (fileSize > 1024 * 1024 * maxSize) {
            return false;
        }

        return true;
    },
    $.validator.format("The total file size cannot exceed {0}MB"),
);
