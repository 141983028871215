import $ from "jquery";
import { IComponent } from "../types/IComponent.type";

class VoteForm {
    static get config(): IComponent {
        return {
            namespace: "al.vf",
            id: "vote-form",
            pluginName: "vote-form",
        };
    }

    $el: JQuery<HTMLElement>;
    $claimOptionEl: JQuery<HTMLElement>;
    $newVoteOptionEl: JQuery<HTMLElement>;
    $windDownVoteOptionEl: JQuery<HTMLElement>;
    $newBusinessVoteOptionsEl: JQuery<HTMLElement>;
    $windDownVoteOptionsEl: JQuery<HTMLElement>;
    $submitButtonEl: JQuery<HTMLElement>;
    $formSteps: JQuery<HTMLElement>;
    $formBackBtn: JQuery<HTMLElement>;
    $formNextBtn: JQuery<HTMLElement>;
    $voteAlert: JQuery<HTMLElement>;
    $thirdParty: JQuery<HTMLElement>;
    currentFormStep = 0;
    isThirdParty: boolean;

    constructor(context: HTMLElement) {
        this.$el = $(context);
        this.$newBusinessVoteOptionsEl = this.$el.find("[data-new-business-vote-options]");
        this.$windDownVoteOptionsEl = this.$el.find("[data-wind-down-vote-options]");
        this.$newVoteOptionEl = this.$el.find("[data-new-vote-option]");
        this.$windDownVoteOptionEl = this.$el.find("[data-wind-down-vote-option]");
        this.$claimOptionEl = this.$el.find("[data-claim-option]");
        this.$submitButtonEl = this.$el.find("[data-form-submit]");
        this.$formSteps = this.$el.find("[data-form-step]");
        this.$formBackBtn = this.$el.find("[data-form-back]");
        this.$formNextBtn = this.$el.find("[data-form-next]");
        this.$voteAlert = this.$el.find("[data-vote-alert]");
        this.$thirdParty = this.$el.find("[data-third-party]");
        this.isThirdParty = !!this.$thirdParty.length;

        this.attachEvents();

        // Remove Is Valid Claim question for 3p users.
        if (this.isThirdParty) {
            this.$formSteps = this.$formSteps.filter(i => i > 0);
        }

        const claimValue = this.getRadioCheckedValue("[data-claim-option]").val() as string;
        this.toggleFormStep(this.currentFormStep);

        // Do not disable submit or run claim validation for 3p users
        !this.isThirdParty && this.toggleSubmitState();
        !this.isThirdParty && this.onClaimOptionChange(claimValue === "true" ? "true" : "false");
    }

    public attachEvents(): void {
        // Ignore valid claim change events for 3p users, its not shown.
        if (!this.isThirdParty) {
            this.$claimOptionEl.on("change", evt => {
                this.onClaimOptionChange((<HTMLInputElement>evt.target).value);
                this.toggleSubmitState();
            });
        }

        this.$newVoteOptionEl.on("change", () => {
            this.toggleSubmitState();
        });

        this.$windDownVoteOptionEl.on("change", () => {
            this.toggleSubmitState();
        });

        this.$formBackBtn.on("click", () => {
            this.toggleFormStep(this.currentFormStep - 1);
        });

        this.$formNextBtn.on("click", () => {
            this.toggleFormStep(this.currentFormStep + 1);
        });
    }

    public onClaimOptionChange(value: string): void {
        const hasValidClaim = value === "true";
        const [windDownVoteValue, newBusinessVoteValue] = this.getCheckedVoteValues();

        if (!hasValidClaim) {
            [windDownVoteValue, newBusinessVoteValue].forEach(el => el.prop("checked", false));
        }

        this.$submitButtonEl.toggleClass("hidden", hasValidClaim);
        this.$formNextBtn.toggleClass("hidden", !hasValidClaim);
    }

    public toggleFormStep(step: number) {
        if (step < 0 || step > this.$formSteps.length - 1) {
            return;
        }

        const isFirstStep = step === 0;
        const isLastStep = step === this.$formSteps.length - 1;

        this.currentFormStep = step;

        this.$formSteps.each((i, el) => {
            $(el).toggleClass("hidden", i !== step);
        });

        this.$formBackBtn.toggleClass("hidden", isFirstStep);
        this.$submitButtonEl.toggleClass("hidden", !isLastStep);
        this.$formNextBtn.toggleClass("hidden", isLastStep);
        this.$voteAlert.toggleClass("hidden", !isFirstStep || this.isThirdParty);

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }

    public getRadioCheckedValue(selector: string): JQuery<HTMLElement> {
        return this.$el.find(`${selector}:checked`);
    }

    public getCheckedVoteValues(): [JQuery<HTMLElement>, JQuery<HTMLElement>] {
        const windDownVoteValue = this.getRadioCheckedValue("[data-new-vote-option]");
        const newBusinessVoteValue = this.getRadioCheckedValue("[data-wind-down-vote-option]");

        return [windDownVoteValue, newBusinessVoteValue];
    }

    public toggleSubmitState(): void {
        const claimValue = this.getRadioCheckedValue("[data-claim-option]");
        const shouldDisable = claimValue.length === 0;

        this.$submitButtonEl.prop("disabled", shouldDisable);
    }
}

export default VoteForm;
