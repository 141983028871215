import $ from "jquery";
import { IComponent } from "../types/IComponent.type";

class ClaimAddDocument {
    static get config(): IComponent {
        return {
            namespace: "al.clad",
            id: "claim-add-document",
            pluginName: "claim-add-document",
        };
    }

    $el: JQuery<HTMLElement>;
    $addForm: JQuery<HTMLElement>;

    personId: string;
    addUrl: string;
    fc: number;
    maxFc: number;
    antiForgeryToken: string;

    constructor(context: HTMLElement) {
        this.$el = $(context);

        const formSelector = this.$el.attr("data-form");

        this.$addForm = $(formSelector);

        this.personId = this.$el.attr("data-person-id");
        this.fc = +this.$el.attr("data-fc");
        this.maxFc = +this.$el.attr("maxfilecount");
        this.addUrl = this.$addForm.attr("action");
        this.antiForgeryToken = this.$addForm.find("input[name='__RequestVerificationToken']").val() as string;

        this.attachEvents();
    }

    public attachEvents(): void {
        this.$el.on("change", this.onAddDocument);
    }

    public onAddDocument = (evt: JQuery.ChangeEvent<HTMLInputElement>) => {
        const claimId = this.$el.attr("data-claim");

        if (this.fc + evt.target.files.length > this.maxFc) {
            $(document).trigger("reparse-validation", this.$el);
            return;
        }

        const data = {
            __RequestVerificationToken: this.antiForgeryToken,
            PersonId: this.personId,
            ClaimId: claimId,
        };

        const fData = new FormData();

        Object.keys(data).forEach(key => {
            fData.append(key, data[key]);
        });

        for (let i = 0; i < evt.target.files.length; i++) {
            fData.append("Files", evt.target.files[i]);
        }

        fetch(this.addUrl, {
            method: "POST",
            body: fData,
        })
            .then(res => res.json())
            .then(res => {
                res && window.location.reload();
            });
    };
}

export default ClaimAddDocument;
