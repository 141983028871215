import "../../styles/site.scss";

import $ from "jquery";

declare let window;
window.$ = window.JQuery = $;

import "jquery-validation";
import "jquery-validation-unobtrusive";
import "./validation";

import components from "./components";
import register from "./register";
import initDatadog from "./datadog";

initDatadog();

register(...components);
