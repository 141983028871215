import $ from "jquery";
import { IComponent } from "../types/IComponent.type";

class ClaimAgreementForm {
    static get config(): IComponent {
        return {
            namespace: "al.caf",
            id: "claim-agreement-form",
            pluginName: "claim-agreement-form",
        };
    }

    $el: JQuery<HTMLElement>;
    $checkboxEl: JQuery<HTMLInputElement>;
    $claimAreaEl: JQuery<HTMLElement>;

    constructor(context: HTMLElement) {
        this.$el = $(context);
        this.$checkboxEl = this.$el.find<HTMLInputElement>("[data-claim-checkbox]");
        this.$claimAreaEl = this.$el.find("[data-claim-type-area]");

        this.attachEvents();

        this.onCheckboxChange();
    }

    public attachEvents(): void {
        this.$checkboxEl.on("change", this.onCheckboxChange);
    }

    public onCheckboxChange = () => {
        const checked = this.$checkboxEl[0].checked;

        if (this.$claimAreaEl.length > 0) {
            this.$claimAreaEl.toggleClass("hidden", !checked);
        }
    };
}

export default ClaimAgreementForm;
