import Navigation from "./Navigation";
import PracticeStatementLetter from "./PracticeStatementLetter";
import VoteForm from "./VoteForm";
import FormLoader from "./FormLoader";
import AddressLookup from "./AddressLookup";
import VideoHandler from "./VideoHandler";
import Collapsible from "./Collapsible";
import SuggestQuestionForm from "./SuggestQuestionForm";
import ProxyCode from "./ProxyCode";
import VoteValueForm from "./VoteValueForm";
import ProxyAppointmentForm from "./ProxyAppointment";
import RedirectLogin from "./RedirectLogin";
import ClaimAgreementForm from "./ClaimAgreementForm";
import ClaimRemoveDocument from "./ClaimRemoveDocument";
import FieldFormatter from "./FieldFormatter";
import ClaimAddDocument from "./ClaimAddDocument";
import ClaimAffordability from "./ClaimAffordability";
import Banner from "./Banner";

export default [
    Banner,
    Navigation,
    PracticeStatementLetter,
    VoteForm,
    FormLoader,
    AddressLookup,
    VideoHandler,
    Collapsible,
    SuggestQuestionForm,
    ProxyCode,
    VoteValueForm,
    ProxyAppointmentForm,
    RedirectLogin,
    ClaimAgreementForm,
    ClaimRemoveDocument,
    FieldFormatter,
    ClaimAddDocument,
    ClaimAffordability,
];
