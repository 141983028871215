import $ from "jquery";
import { IComponent } from "../types/IComponent.type";

class ProxyCode {
    static get config(): IComponent {
        return {
            namespace: "al.cpc",
            id: "proxy-code",
            pluginName: "proxy-code",
        };
    }

    $el: JQuery<HTMLElement>;
    $snippetEl: JQuery<HTMLElement>;
    $proxyEl: JQuery<HTMLInputElement>;
    $copyBtnEl: JQuery<HTMLElement>;
    $showBtnEl: JQuery<HTMLElement>;

    constructor(context: HTMLElement) {
        this.$el = $(context);
        this.$snippetEl = this.$el.find("#proxy-code-snippet");
        this.$proxyEl = this.$el.find<HTMLInputElement>("#proxy-code");
        this.$copyBtnEl = this.$el.find("#copy-btn");
        this.$showBtnEl = this.$el.find("#show-btn");

        this.attachEvents();
    }

    public attachEvents(): void {
        const showBtnEl = this.$showBtnEl?.get(0);
        const copyBtnEl = this.$copyBtnEl.get(0);
        const snippetEl = this.$snippetEl?.get(0);

        this.$showBtnEl?.on("click", () => {
            showBtnEl.classList.add("hidden");
            copyBtnEl.classList.remove("hidden");
            snippetEl.classList.remove("hidden");
        });
        this.$copyBtnEl.on("click", () => {
            this.copyCode();
            copyBtnEl.innerHTML = "Copied code!";
            copyBtnEl.classList.remove("btn--secondary");
            copyBtnEl.classList.add("btn--success");

            setTimeout(() => {
                copyBtnEl.classList.remove("btn--success");
                copyBtnEl.classList.add("btn--secondary");
                copyBtnEl.innerHTML = "Copy code";
            }, 1500);
        });
    }

    public copyCode() {
        const proxyEl = this.$proxyEl.get(0);
        proxyEl.select();
        proxyEl.setSelectionRange(0, 99999);
        document.execCommand("copy");
    }
}

export default ProxyCode;
