import $ from "jquery";
import { IComponent } from "../types/IComponent.type";

class RedirectLogin {
    static get config(): IComponent {
        return {
            namespace: "al.rdl",
            id: "redirect-login",
            pluginName: "redirect-login",
        };
    }

    $el: JQuery<HTMLElement>;

    constructor(context: HTMLElement) {
        this.$el = $(context);

        this.redirectIfRequired();
    }

    public redirectIfRequired(): void {
        const cookieKey = "scheme.post-error.refresh";
        const cookie = this.getCookie<string>(cookieKey) === "refresh";
        const urlIsBad = window.location.href.endsWith("signin-oidc");

        const addMinutes = (date, minutes) => {
            return new Date(date.getTime() + minutes * 60000);
        };

        if (!cookie && urlIsBad) {
            this.setCookie<string>(cookieKey, "refresh", addMinutes(new Date(Date.now()), 15));
            window.location.href = "/claim";
        }
    }

    setCookie<T>(name: string, val: T, expiry: Date) {
        const value = this.mapToCookieValue<T>(val);
        // Set it
        document.cookie = `${name}=${value}; expires=${expiry.toUTCString()}; path=/; Secure; SameSite=Strict`;
    }

    getCookie<T>(name: string): T | undefined {
        const value = "; " + document.cookie;
        const parts = value.split("; " + name + "=");
        if (parts && parts.length === 2) {
            return this.mapFromCookieValue<T>(
                parts
                    .pop()
                    ?.split(";")
                    .shift(),
            );
        }
    }

    mapFromCookieValue<T>(stringVal?: string): T | undefined {
        try {
            if (!stringVal || stringVal.length === 0) return;
            return JSON.parse(stringVal) as T;
        } catch (error) {
            return;
        }
    }

    mapToCookieValue<T>(value: T): string {
        switch (typeof value) {
            case "object":
            case "string":
            case "number":
                return JSON.stringify(value);
            case "function":
                return this.mapToCookieValue(value());
            default:
                throw new Error(`Cannot map ${typeof value} to cookie value`);
        }
    }
}

export default RedirectLogin;
