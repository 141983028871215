import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";

export default function initDatadog() {
    const dataDogConfig = (window as any).dataDogConfig;

    datadogRum.init(dataDogConfig);
    datadogLogs.init({
        clientToken: dataDogConfig.clientToken,
        site: dataDogConfig.site,
        forwardErrorsToLogs: true,
        sampleRate: 100,
        useSecureSessionCookie: dataDogConfig.useSecureSessionCookie,
    });

    datadogRum.startSessionReplayRecording();
}
