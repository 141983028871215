import $ from "jquery";
import { IComponent } from "../types/IComponent.type";

class VoteValueForm {
    static get config(): IComponent {
        return {
            namespace: "al.vvf",
            id: "vote-value",
            pluginName: "vote-value",
        };
    }

    $el: JQuery<HTMLElement>;
    $toggle: JQuery<HTMLElement>;
    $form: JQuery<HTMLElement>;
    isOpen = false;

    constructor(context: HTMLElement) {
        this.$el = $(context);
        this.$toggle = this.$el.find("[data-toggle-btn]");
        this.$form = this.$el.find("[data-vote-value-form]");

        this.attachEvents();
    }

    public attachEvents(): void {
        this.$toggle.on("click", () => {
            this.isOpen = !this.isOpen;

            this.$form.toggleClass("hidden", !this.isOpen);
            this.$form.get(0).scrollIntoView();
            this.$toggle.text(this.isOpen ? "Hide Form" : "Show Form");
        });
    }
}

export default VoteValueForm;
