import $ from "jquery";

$.validator.addMethod(
    "maxfilecount",
    function(value: string, element: HTMLInputElement, params: string) {
        const maxCount = parseInt(params, 10);

        if (maxCount == null || isNaN(maxCount)) {
            return true;
        }

        if (element.files.length > maxCount) {
            return false;
        }

        return true;
    },
    $.validator.format("The maximum number of files you can upload is {0}"),
);
