import $ from "jquery";
import { IComponent } from "../types/IComponent.type";

class ProxyAppointmentForm {
    static get config(): IComponent {
        return {
            namespace: "al.prxf",
            id: "proxy-form",
            pluginName: "proxy-form",
        };
    }

    $el: JQuery<HTMLElement>;
    $claimOptionEl: JQuery<HTMLElement>;
    $submitButtonEl: JQuery<HTMLElement>;
    $formBody: JQuery<HTMLElement>;

    constructor(context: HTMLElement) {
        this.$el = $(context);
        this.$claimOptionEl = this.$el.find("[data-claim-option]");
        this.$formBody = this.$el.find("[data-form-body]");
        this.$submitButtonEl = this.$el.find("[data-form-submit]");

        this.attachEvents();
    }

    public attachEvents(): void {
        this.$claimOptionEl.on("change", evt => {
            this.onClaimOptionChange((<HTMLInputElement>evt.target).value);
        });
    }

    public onClaimOptionChange(value: string): void {
        const hasValidClaim = value === "true";

        this.$formBody.toggleClass("hidden", !hasValidClaim);
        this.$submitButtonEl.prop("disabled", !hasValidClaim);
    }

    public getRadioCheckedValue(selector: string): JQuery<HTMLElement> {
        return this.$el.find(`${selector}:checked`);
    }
}

export default ProxyAppointmentForm;
