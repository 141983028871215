import $ from "jquery";
import { IComponent } from "../types/IComponent.type";

class SuggestQuestionForm {
    static get config(): IComponent {
        return {
            namespace: "al.sqf",
            id: "suggest-question-form",
            pluginName: "suggest-question-form",
        };
    }

    $el: JQuery<HTMLElement>;
    $partyOptionEl: JQuery<HTMLElement>;
    $loanOptionEl: JQuery<HTMLElement>;
    $loanOptionsEl: JQuery<HTMLElement>;
    $submitButtonEl: JQuery<HTMLElement>;
    $questionTextArea: JQuery<HTMLElement>;
    $questionCategorySelect: JQuery<HTMLElement>;
    $wordCount: JQuery<HTMLElement>;

    constructor(context: HTMLElement) {
        this.$el = $(context);
        this.$loanOptionEl = this.$el.find("[data-loan-option]");
        this.$questionTextArea = this.$el.find("[data-form-text-area]");
        this.$questionCategorySelect = this.$el.find("[data-form-category]");
        this.$partyOptionEl = this.$el.find("[data-party-option]");
        this.$submitButtonEl = this.$el.find("[data-form-submit]");
        this.$wordCount = this.$el.find("[data-word-count]");

        this.attachEvents();

        this.toggleSubmitState();

        this.updateWordCount();
    }

    attachEvents(): void {
        [this.$questionTextArea, this.$questionCategorySelect, this.$loanOptionEl, this.$partyOptionEl].forEach(el =>
            el.on("change", () => {
                this.toggleSubmitState();
            }),
        );

        this.$questionTextArea.on("keyup", () => {
            this.updateWordCount();
        });
    }

    toggleSubmitState(): void {
        const claimValue = this.$el.find("[data-loan-option]:checked").val();
        const voteValue = this.$el.find("[data-party-option]:checked").val();
        const textAreaVal = this.$questionTextArea.val();
        const categoryVal = this.$questionCategorySelect.val();

        this.$submitButtonEl.prop(
            "disabled",
            !(
                claimValue !== undefined &&
                voteValue !== undefined &&
                textAreaVal !== undefined &&
                textAreaVal !== "" &&
                categoryVal !== undefined
            ),
        );
    }

    updateWordCount(): void {
        const text = this.$questionTextArea
            .val()
            .toString()
            .trim();
        const words = text === "" ? 0 : text?.split(" ").length;
        this.$wordCount.get(0).innerHTML = `${words} / 100`;
    }
}

export default SuggestQuestionForm;
