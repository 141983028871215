import $ from "jquery";
import { IComponent } from "../types/IComponent.type";
import Cookies from "js-cookie";

const COOKIE_NAME = "AmigoSchemeBanner";

class Banner {
    static get config(): IComponent {
        return {
            namespace: "al.alerb",
            id: "alert-banner",
            pluginName: "alert-banner",
        };
    }

    $el: JQuery<HTMLElement>;
    $dismiss: JQuery<HTMLButtonElement>;

    constructor(context: HTMLElement) {
        this.$el = $(context);
        this.$dismiss = this.$el.find<HTMLButtonElement>("[data-dismiss-button]");

        if (Cookies.get(COOKIE_NAME)) {
            this.onDismissClick.bind(this)(true);
        }

        this.attachEvents();
    }

    private attachEvents(): void {
        this.$dismiss.on("click", () => this.onDismissClick.bind(this)(true));
    }

    private onDismissClick(hide: boolean): void {
        this.$el.toggleClass("hidden", hide);
        Cookies.set(COOKIE_NAME, true, { expires: 28 });
    }
}

export default Banner;
