import { IComponent } from "../types/IComponent.type";

class Navigation {
    static get config(): IComponent {
        return {
            namespace: "al.cb",
            id: "navigation",
            pluginName: "navigation",
        };
    }

    el: HTMLElement;
    mobileButton: HTMLElement;
    mobileNavigation: HTMLElement;

    constructor(context: HTMLElement) {
        this.el = context;
        this.mobileButton = this.el.querySelector(`[data-mobile-nav-button]`);
        this.mobileNavigation = this.el.querySelector(`[data-mobile-nav-menu]`);

        if (this.mobileButton == null || this.mobileNavigation == null) {
            return;
        }

        this.attachEvents();
    }

    public attachEvents(): void {
        this.mobileButton.addEventListener("click", () => {
            this.mobileNavigation.classList.toggle("hidden");
        });
    }
}

export default Navigation;
