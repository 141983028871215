import $ from "jquery";
import { IComponent } from "../types/IComponent.type";

class Collapsible {
    static get config(): IComponent {
        return {
            namespace: "al.tl",
            id: "collapsible",
            pluginName: "collapsible",
        };
    }

    $el: JQuery<HTMLElement>;
    $toggler: JQuery<HTMLElement>;
    $toggleTarget: JQuery<HTMLElement>;

    togglerOnText: string;
    togglerOffText: string;

    collapsedClass: string;
    collapsed: boolean;

    constructor(context: HTMLElement) {
        this.$el = $(context);
        this.$toggler = this.$el.find("[data-collapsible-toggler]");
        this.$toggleTarget = this.$el.find("[data-collapsible-target]");

        this.togglerOnText = this.$toggler.attr("data-collapsible-toggler-on");
        this.togglerOffText = this.$toggler.attr("data-collapsible-toggler-off");

        this.collapsed = Boolean(this.$el.attr("data-collapsed"));
        this.collapsedClass = this.$el.attr("data-collapse-class");

        this.collapseTarget(this.collapsed);

        this.attachEvents();
    }

    public collapseTarget(collapse: boolean): void {
        this.collapsed = collapse;
        this.$toggleTarget.toggleClass(this.collapsedClass, collapse);
        this.$toggleTarget.attr("aria-hidden", this.collapsed ? "true" : "false");

        if (this.togglerOffText != null && this.togglerOnText != null) {
            this.$toggler.html(this.collapsed ? this.togglerOnText : this.togglerOffText);
        }
    }

    public attachEvents(): void {
        this.$toggler.on("click", () => {
            this.collapseTarget(!this.collapsed);
        });
    }
}

export default Collapsible;
