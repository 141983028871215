import $ from "jquery";
import { IComponent } from "../types/IComponent.type";

class VideoHandler {
    static get config(): IComponent {
        return {
            namespace: "al.yvh",
            id: "video-handler",
            pluginName: "video-handler",
        };
    }

    $el: JQuery<HTMLElement>;
    $video: JQuery<HTMLElement>;
    $overlay: JQuery<HTMLElement>;
    $close: JQuery<HTMLElement>;
    $youtubeEmbeds: JQuery<HTMLElement>;
    isLoading = false;
    onLoadClass = "";

    constructor(context: HTMLElement) {
        this.$el = $(context);
        this.$video = this.$el.find("[data-video-handler-video]");
        this.$overlay = this.$el.find("[data-video-handler-overlay]");
        this.$close = this.$el.find("[data-video-handler-close]");

        this.$youtubeEmbeds = this.$el.find('[data-oembed-provider="youtube"]');
        this.onLoadClass = this.$el.attr("data-video-handler-loaded");

        if (this.$youtubeEmbeds && this.$youtubeEmbeds.length > 0) {
            this.$youtubeEmbeds.each((i, el) => {
                el.classList.add("c-video-contents__video");
                el.children[0].classList.add("c-video-contents__video");
            });
        }

        this.attachEvents();
    }

    private attachEvents() {
        this.$overlay.on("click", this.onOverlayClick);
        this.$close.on("click", this.onCloseClick);
    }

    private onCloseClick = () => {
        this.$video.attr("src", null);
        this.$el.removeClass(this.onLoadClass);
        this.$overlay.show();
        this.isLoading = false;
    };

    private onOverlayClick = () => {
        const onLoad = () => {
            if (this.$video.attr("src") != null) {
                this.$el.addClass(this.onLoadClass);
                this.$overlay.hide();
                this.isLoading = false;
            }
        };

        if (this.isLoading) {
            return;
        }

        const isVideo = this.$video.is("video");

        this.$overlay.addClass("loading");
        this.isLoading = true;

        const src = this.$video.attr("data-src");

        // If youtube, autoplay
        if (!isVideo) {
            this.$video.attr("src", src + "&autoplay=1");
            this.$video.on("load", () => onLoad());
        }

        if (isVideo) {
            this.$video.attr("src", src);
            (this.$video as JQuery<HTMLVideoElement>).get(0).play();
            this.$video.on("loadeddata", () => onLoad());
        }
    };
}

export default VideoHandler;
