import $ from "jquery";
import { IComponent } from "../types/IComponent.type";

class ClaimRemoveDocument {
    static get config(): IComponent {
        return {
            namespace: "al.caf",
            id: "claim-remove-document",
            pluginName: "claim-remove-document",
        };
    }

    $el: JQuery<HTMLElement>;
    $removeForm: JQuery<HTMLElement>;
    $removeBtn: JQuery<HTMLElement>;

    personId: string;
    removeUrl: string;
    antiForgeryToken: string;

    constructor(context: HTMLElement) {
        this.$el = $(context);

        const formSelector = this.$el.attr("data-form");

        this.$removeForm = $(formSelector);
        this.$removeBtn = this.$el.find("[data-remove-button]");

        this.personId = this.$el.attr("data-person-id");
        this.removeUrl = this.$removeForm.attr("action");
        this.antiForgeryToken = this.$removeForm.find("input[name='__RequestVerificationToken']").val() as string;

        this.attachEvents();
    }

    public attachEvents(): void {
        this.$removeBtn.on("click", this.onRemoveDocument);
    }

    public onRemoveDocument = (evt: JQuery.ClickEvent<HTMLElement>) => {
        const btn = $(evt.target);
        const documentId = btn.attr("data-remove-button");

        const data = {
            __RequestVerificationToken: this.antiForgeryToken,
            PersonId: this.personId,
            DocumentId: documentId,
        };

        const formBody: string[] = [];

        for (const property in data) {
            const encodedKey = encodeURIComponent(property);
            const encodedValue = encodeURIComponent(data[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }

        const encodedBody = formBody.join("&");

        fetch(this.removeUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            },
            body: encodedBody,
        })
            .then(function(res) {
                return res.json();
            })
            .then(function(res) {
                if (res) {
                    window.location.reload();
                } else {
                    // failed to delete
                }
            });
    };
}

export default ClaimRemoveDocument;
