import $ from "jquery";

function reparseFormElements(selector: string) {
    //use the normal unobstrusive.parse method

    if (!$.validator || !$.validator.unobtrusive) return;

    $.validator.unobtrusive.parse(selector);

    const form = $(selector).closest("form");
    const unobtrusiveValidation = form.data("unobtrusiveValidation");
    const validator = (form as any).validate();

    $.each(unobtrusiveValidation.options.rules, function(elname: string, elrules) {
        if (validator.settings.rules[elname] == undefined) {
            const args = {} as { messages: { [key: string]: any } };
            $.extend(args, elrules);
            args.messages = unobtrusiveValidation.options.messages[elname];
            ($('[name="' + elname + '"]') as any).rules("add", args);
        } else {
            $.each(elrules, function(rulename, data) {
                if (validator.settings.rules[elname][rulename] == undefined) {
                    const args = {} as { messages: { [key: string]: any } };
                    args[rulename] = data;
                    args.messages = unobtrusiveValidation.options.messages[elname][rulename];
                    ($('[name="' + elname + '"]') as any).rules("add", args);
                }
            });
        }
    });
}

$(document).on("reparse-validation", function(evt, selector) {
    reparseFormElements(selector);
});
