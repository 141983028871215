import $ from "jquery";
import { IComponent } from "../types/IComponent.type";

/**
 * Valid settings for the formatter.
 */
const FIELD_FORMATTER_REGEX = /^data-field-formatter-/g;

/**
 * External attributes that are supported, i.e. integrating
 * the formatter with ms unobtrusive javascript library. This
 * provides aliases for the settings when it sees the
 * attributes.
 **/
const FORMATTER_ALLOWED_SETTINGS = {
    "data-val-range-min": "rangemin",
    "data-val-range-max": "rangemax",
};

/**
 * A registry containing all the formatters, they have the
 * following settings:
 * [order]       If there are multiple formatters on a field, the order
 *               determines when they'll be executed.
 *
 * [event]       The event that will trigger the formatter, can be chained,
 *               i.e. input blur
 *
 * [formatter]   The function which formats the input.
 *
 * [requires]    A helper to ensure that the settings used by the formatter
 *               have been specified, if they haven't the formatter won't
 *               be executed and a warning will appear.
 *
 * [beforeEvent] This will trigger before the formating begins.
 *
 * [afterEvent]  This will trigger after formating has occurred.
 **/
const FORMATTER_REGISTRY = {
    maxlength: {
        order: 1,
        event: "input",
        formatter: function(input) {
            const maxLength = this.settings.maxlength;
            const testInput = input.toString();
            const fixedInput = testInput.substr(0, maxLength);

            if (typeof input === "number") {
                return parseInt(fixedInput);
            }

            return fixedInput;
        },
        requires: ["maxlength"],
    },
    leftpad: {
        order: 2,
        event: "blur",
        formatter: function(input) {
            const leftPad = this.settings.leftpad;
            const leftPadValue = this.settings.leftpadvalue;
            const testInput = input.toString();
            const delta = leftPad.length - testInput.length;

            if (delta <= 0 || input == "") {
                return input;
            }

            const paddedValue = (function(total, value) {
                const arr = [];

                for (let index = 0; index < total; index++) {
                    arr.push(value);
                }

                return arr.join("");
            })(delta, leftPadValue);

            return paddedValue + input;
        },
        requires: ["leftpad", "leftpadvalue"],
    },
    dobyear: {
        order: 2,
        event: "blur",
        formatter: function(input) {
            const startRange = parseInt(this.settings.rangemin);
            const endRange = parseInt(this.settings.rangemax);
            const testInput = input.toString();

            if (startRange > endRange) {
                return input;
            }

            if (testInput.length < 2) {
                return input;
            }

            const yearsMatched = (function(start, end, input) {
                const years = [];
                while (start <= end) {
                    const checkYear = start.toString().substr(-input.length);
                    if (checkYear == input) {
                        years.push(start);
                    }
                    start++;
                }
                return years;
            })(startRange, endRange, testInput);

            if (yearsMatched.length === 1) {
                return yearsMatched[0];
            }

            return input;
        },
        requires: ["rangemin", "rangemax"],
    },
};

function formatterExists(attr) {
    const keys = Object.keys(FORMATTER_REGISTRY);
    return keys.indexOf(attr) !== -1;
}

function isFormatterSetting(attrName) {
    return FIELD_FORMATTER_REGEX.test(attrName);
}

function sanitizeAttributeName(attrName) {
    if (typeof attrName !== "string") {
        return "";
    }

    const sanitizedName = attrName.trim().replace(FIELD_FORMATTER_REGEX, "");
    return sanitizedName;
}

function getFormatterSettings($el): any {
    if ($el == null) {
        return [];
    }

    const formatters = [];
    const formatterValues = {};
    const attrs = Array.prototype.slice.call($el[0].attributes);

    for (let index = 0; index < attrs.length; index++) {
        const attr = attrs[index];

        if (!isFormatterSetting(attr.name)) {
            const inAliases = FORMATTER_ALLOWED_SETTINGS[attr.name];

            if (inAliases != null) {
                formatterValues[inAliases] = attr.value;
            }

            continue;
        }

        const sanitizedAttrName = sanitizeAttributeName(attr.name);

        if (attr != null && formatterExists(sanitizedAttrName)) {
            formatters.push(sanitizedAttrName);
        }

        formatterValues[sanitizedAttrName] = attr.value;
    }

    return {
        formatters: formatters,
        values: formatterValues,
    };
}

class FieldFormatter {
    static get config() {
        return {
            namespace: "identity.flv",
            id: "field-formatter",
            pluginName: "field-fornatter",
        };
    }

    $el: JQuery<HTMLElement>;
    formatters: any[];
    settings: any;

    constructor(context) {
        this.$el = $(context);
        const settings = getFormatterSettings(this.$el);
        this.formatters = settings.formatters;
        this.settings = settings.values;
        this.init();
    }

    init() {
        this.attachEvents();
    }

    attachEvents() {
        if (this.formatters.length <= 0) {
            return;
        }

        const eventFormatters = {};

        for (let index = 0; index < this.formatters.length; index++) {
            const formatter = FORMATTER_REGISTRY[this.formatters[index]];
            const events = formatter.event.split(" ");

            for (let eventIndex = 0; eventIndex < events.length; eventIndex++) {
                const event = events[eventIndex];
                eventFormatters[event] = (eventFormatters[event] || []).concat([formatter]);
            }
        }

        const allEvents = Object.keys(eventFormatters);

        for (let allIndex = 0; allIndex < allEvents.length; allIndex++) {
            const eventKey = allEvents[allIndex];
            const eventFormatter = eventFormatters[eventKey];
            this.$el.on(eventKey, this.onEvent(eventKey, eventFormatter));
        }
    }

    validSettings = formatter => {
        if (formatter.requires == null) {
            return true;
        }

        let isValid = true;

        for (let index = 0; index < formatter.requires.length; index++) {
            const argRequired = formatter.requires[index];

            if (this.settings[argRequired] == null) {
                console.warn("The setting '" + argRequired + "' is required.");
                isValid = false;
            }
        }

        return isValid;
    };

    onEvent = (eventKey: any, eventFormatter: any) => {
        return evt => {
            let value = evt.target.value;

            if (this.formatters.length <= 0) {
                return;
            }

            for (let index = 0; index < eventFormatter.length; index++) {
                const formatterInstance = eventFormatter[index];

                if (typeof formatterInstance.beforeEvent === "function") {
                    formatterInstance.beforeEvent.call(this, value);
                }

                if (this.validSettings(formatterInstance)) {
                    value = formatterInstance.formatter.call(this, value);
                }

                if (typeof formatterInstance.afterEvent === "function") {
                    formatterInstance.afterEvent.call(this, value);
                }
            }

            this.$el.val(value);

            if (eventKey == "blur" && typeof (this.$el as any).valid == "function") {
                (this.$el as any).valid();
            }
        };
    };
}

export default FieldFormatter;
