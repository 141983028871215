import $ from "jquery";
import { IComponent } from "../types/IComponent.type";
import _ from "lodash";

class ClaimAffordability {
    static get config(): IComponent {
        return {
            namespace: "al.claccf",
            id: "claim-accept-form",
            pluginName: "claim-accept-form",
        };
    }

    $el: JQuery<HTMLElement>;
    $agreementRadios: JQuery<HTMLElement>;
    $declineFields: JQuery<HTMLElement>;

    constructor(context: HTMLElement) {
        this.$el = $(context);
        this.$agreementRadios = this.$el.find("[data-decline-option]");
        this.$declineFields = this.$el.find("[data-decline-field]");

        this.attachEvents();
    }

    public attachEvents(): void {
        this.$agreementRadios.each((i, el) => {
            $(el).on("change", this.handleRadioChange.bind(this));
        });

        this.handleRadioChange();
    }
    public handleRadioChange() {
        this.toggleDeclineFieldsHidden(this.allRadiosAreTrue("[data-decline-option]"));
    }

    public toggleDeclineFieldsHidden(hide: boolean) {
        this.$declineFields.each((i, el) => {
            $(el).toggleClass("hidden", hide);
        });
    }

    public allRadiosAreTrue(selector: string) {
        return Array.from(document.querySelectorAll<HTMLInputElement>(`${selector}:checked`)).every(
            el => el.value === "true",
        );
    }
}

export default ClaimAffordability;
