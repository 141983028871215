import $ from "jquery";
import { IComponent } from "../types/IComponent.type";

const ns = "address",
    postcode = '[data-address-field="postcode"]',
    loaderElement = "[data-address-loading]",
    addressDetails = '[data-address="details"]',
    addressCache = [],
    hide = "hidden",
    addressFields = [
        ['[data-address-field="line-1"]', "addressLine1"],
        ['[data-address-field="line-2"]', "addressLine2"],
        ['[data-address-field="town"]', "town"],
        ['[data-address-field="county"]', "county"],
        [postcode, "PostCode"],
    ],
    events = {
        init: 1,
        search: 2,
        showAddressSelect: 3,
        selectAddress: 4,
        noResults: 5,
        addressNotInList: 6,
    },
    validatorEnabled = true;

function getPostcode(inst) {
    return inst.$postcode
        .val()
        .toUpperCase()
        .replace(" ", "");
}

class AddressLookup {
    static get config(): IComponent {
        return {
            namespace: "al.al",
            id: "address-lookup",
            pluginName: "address-lookup",
        };
    }

    $el: JQuery<HTMLElement>;
    $addressDetails: JQuery<HTMLElement>;
    $postcode: JQuery<HTMLElement>;
    $loaderElement: JQuery<HTMLElement>;
    $results: JQuery<HTMLElement>;

    searchUrl: string;
    loadingClass: string;
    loading: boolean;

    constructor(el: HTMLElement) {
        this.$el = $(el);
        this.init();
    }

    public init() {
        this.searchUrl = this.$el.data("address-url");
        this.loadingClass = this.$el.data("address-loading-class");
        this.$addressDetails = this.$el.find(addressDetails);
        this.$postcode = this.$el.find(postcode);
        this.$loaderElement = this.$el.find(loaderElement);
        this.loading = false;
        this.$results = null;

        this.$el
            .on("keyup." + ns + " blur." + ns, postcode, $.proxy(this.search, this))
            .on("keydown." + ns, postcode, $.proxy(this.preventSubmit, this));

        this.updateUI(events.init);
    }

    public preventSubmit(evt) {
        if (evt.keyCode == 13) {
            evt.preventDefault();
            return false;
        }
    }

    public search(evt?) {
        // Allow people to tab backwards through the address lookup.
        if (evt.which === 0 || evt.which === 9 || evt.which === 16) return;

        this.updateUI(events.search);

        if (getPostcode(this).length < 5 || (validatorEnabled && !(this.$postcode as any).valid())) {
            return;
        }

        const postCode = getPostcode(this);

        if (postCode in addressCache) {
            this.handleResponse(postCode, addressCache[postCode]);
        } else if (!this.loading) {
            this.loading = true;
            this.isLoading(true);
            $.post(this.searchUrl, { postCode: postCode }, $.proxy(this.handleResponse, this, postCode), "json");
        }
    }

    public handleResponse(postCode: string, data) {
        this.loading = false;

        if (!(postCode in addressCache)) addressCache[postCode] = data; // Cache the address response

        this.isLoading(false);

        if (data.length === 1) {
            this.updateUI(events.selectAddress, data[0]);
        } else if (data.length > 1) {
            this.showAddressSelector(data);
        } else {
            this.updateUI(events.noResults);
            this.$results = $(
                '<div class="field-validation-error postcode-field-validation">' +
                    this.$el.data("address-noresults-msg") +
                    "</div>",
            );
            this.$postcode.after(this.$results);
        }
    }

    public isLoading(loading: boolean) {
        this.$loaderElement.toggleClass(this.loadingClass, loading);
    }

    public showMessage(msgSelector: string, show: boolean) {
        this.$el.find(msgSelector).toggleClass(hide, !show);
    }

    public showAddressSelector(addresses: any[]) {
        const $option = $('<option value="">Select address</option>'),
            $select = $(
                '<select class="input my-2" name="address-results" id="address_results" data-val="true" data-val-required="Please select an address" />',
            ).append($option),
            $errMsg = $('<span data-valmsg-for="address-results" data-valmsg-replace="true"></span>'),
            $noAddressBtn = $(
                '<a class="btn btn--secondary btn--sm manual-address-btn-link" tabindex="0">My address isn\'t here</a>',
            );

        $.each(
            addresses,
            $.proxy(function(i, v) {
                $select.append(
                    $option
                        .clone()
                        .attr("value", i)
                        .text(v.formattedAddressString),
                );
            }, this),
        );

        const $selectWrapper = $('<div class="select" />');
        const $selectArrow = $('<div class="select__arrow" />');
        $selectWrapper.append([$select, $selectArrow]);

        this.$results = $('<div class="form-group address-select"/>').append([$selectWrapper, $errMsg, $noAddressBtn]);

        this.$addressDetails.before(this.$results);

        $(document).trigger("reparse-validation", this.$results);

        $noAddressBtn.on("keypress click." + ns, $.proxy(this.addressNotInList, this));

        $select.on("change." + ns, $.proxy(this.selectAddress, this)).focus();

        this.updateUI(events.showAddressSelect);
    }

    public selectAddress(evt) {
        const $target = $(evt.target),
            addressId = $target.find("option:selected").attr("value"),
            address = addressCache[getPostcode(this)][addressId];

        if (typeof address != "undefined") {
            $target.trigger("blur");
            this.updateUI(events.selectAddress, address);
        }
    }

    public addressNotInList(evt) {
        evt.preventDefault();

        // If the event is not a click or a focused enter keypress, ignore it.
        if (evt.which !== 1 && evt.which !== 13 && evt.which !== 32) return;

        this.updateUI(events.addressNotInList);
    }

    public updateAddressFields(address) {
        if (!address) address = {};

        $.each(
            addressFields,
            $.proxy(function(i, v) {
                this.$addressDetails.find(v[0]).val(address[v[1]]);
            }, this),
        );
    }

    public updateUI(eventName, selectedAddress?) {
        let hideAddressDetails = false,
            updateAddressDetails = false,
            removeResults = true;

        switch (eventName) {
            case events.init: {
                const addressLineOneIsBlank =
                    (this.$addressDetails.find(addressFields[0][0]).val() as string).length == 0;

                if ((this.$postcode.val() as string).length > 0 && addressLineOneIsBlank) {
                    this.search();
                    return;
                }

                hideAddressDetails = addressLineOneIsBlank;
                break;
            }
            case events.search:
                updateAddressDetails = true;
                hideAddressDetails = true;
                break;
            case events.showAddressSelect:
                removeResults = false;
                hideAddressDetails = true;
                break;
            case events.selectAddress:
                updateAddressDetails = true;
                break;
            case events.noResults:
                removeResults = false;
                updateAddressDetails = true;
                break;
            case events.addressNotInList:
                updateAddressDetails = true;
                break;
        }

        this.$addressDetails.toggleClass(hide, hideAddressDetails);

        if (updateAddressDetails) this.updateAddressFields(selectedAddress);

        if (this.$results && removeResults) {
            this.$results.remove();
            this.$results = null;
        }
    }
}

export default AddressLookup;
